import { Provider } from '@learnn/analytics/src/providers'
import env from '../../env.json'
import { PostHog } from 'posthog-js'

export default function createPostHogAnalyticsProvider(posthog: PostHog): Provider {
  const logEvent = async (name: string, params: { [key: string]: any }) => {
    posthog.capture(name, params)
  }
  const logSelectContent = async (contentType: string, itemId: string) =>
    logEvent('select_content', { contentType, itemId })
  const logLogin = async (method?: string) => logEvent('login', { method: method ?? 'strapi' })
  const logScreenView = async (_screenClass: string, screenName: string) => {
    posthog.capture('$pageview', { screenName })
  }
  const setUserProperties = async (properties: { [key: string]: any }) => {
    posthog.capture('$set', {
      $set: properties,
    })
  }

  const setUserProperty = async (name: string, value: any) => {
    let o = {}
    o[name] = value
    posthog.capture('$set', {
      $set: o,
    })
  }
  const setUserId = async (id: string) => posthog.identify(id)
  const setUserEmail = async (email: string) => {
    posthog.capture('$set', {
      $set_once: { email },
    })
  }

  return {
    logLogin,
    logSelectContent,
    logEvent,
    logScreenView,
    setUserId,
    setUserEmail,
    setUserProperties,
    setUserProperty,
    name: 'posthog',
  }
}

export const initializationOptions = {
  api_host: env.WEB_PUBLIC_POSTHOG_API_HOST,
  ui_host: env.WEB_PUBLIC_POSTHOG_UI_HOST,
  person_profiles: 'identified_only',
  autocapture: false,
  capture_pageview: false,
  disable_session_recording: true,
}
