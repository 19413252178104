import { useState, useEffect, useRef } from 'react'
import Row from 'react-bootstrap/Row'
import Footer from '../../components/Footer'
import Loader from 'react-loader-spinner'
import InputPassword from '../../components/InputPassword'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { AlertBanner } from '../../components/AlertBannerSignup'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { createFreeAccount } from '../../controllers/User'
import { getCountryCodeFromIp } from '../../controllers/Payment'
import auth from '../../utils/auth'
import { useGTMCheckout } from '../../analytics/gtm'
import QueryString from 'query-string'
import env from '../../env.json'
import { getCookie } from '../../utils/checkout'
import style from './freeRegistrationScreen.module.css'
import { SocialLoginButtons } from '../../components/SocialLoginButtons/SocialLoginButtons'
import { LiveChat } from '../../components/LiveChat'
import {
  VerticalStack,
  Title,
  HorizontalStack,
  Text,
  useTheme,
  TextInput,
  Box,
  Button,
} from '@learnn/designn'
import { useBodyClass } from '../../utils/hooks'
import { osName } from 'react-device-detect'

type UrlParams = {
  email?: string
  appUrl?: string
  social?: string
  next_step: string
  utm_campaign?: string
  utm_content?: string
  utm_medium?: string
  utm_source?: string
}

type UtmParams = {
  gtmSource: string | null
  gtmMedium: string | null
  gtmCampaign: string | null
  gtmContent: string | null
}

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Inserisci un indirizzo email valido')
    .required('Inserisci un indirizzo email'),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'La password deve contenere almeno 8 caratteri, una lettera maiuscola, una minuscola e un numero.',
    )
    .required('Inserisci una password'),
})

const extractEmail = urlParams => {
  if (urlParams.email) {
    return decodeURI(urlParams?.email)
  }
  return ''
}
const extractAuthToken = () => {
  return auth.getToken()
}

const extractUtmParams = () => {
  const gtmSource = getCookie('gtm_source')
  const gtmMedium = getCookie('gtm_medium')
  const gtmCampaign = getCookie('gtm_campaign')
  const gtmContent = getCookie('gtm_content')

  return { gtmSource, gtmMedium, gtmCampaign, gtmContent }
}

const extractGaId = urlParams => urlParams?.gcid
const createForwardLink = (urlParams, locationSearch) => {
  const link = urlParams.next_step ?? env.SECOND_STEP_URL
  const queryString = stripQueryString(locationSearch)

  return link + queryString
}

function stripQueryString(locationSearch) {
  const parametersToStrip = ['next_step', 'email', 'appUrl']
  return QueryString.exclude(locationSearch, parametersToStrip)
}

const goToLogin = urlParams => {
  const appUrl = urlParams.appUrl ?? urlParams.next_step
  if (appUrl) {
    window.location.replace(`/login/?appurl=${appUrl}`)
  } else {
    window.location.replace('/login')
  }
}

const prepareUtmData = (urlParams: UrlParams, cookiesParams: UtmParams) => {
  const gtmSource = cookiesParams.gtmSource ?? urlParams.utm_source ?? null
  const gtmCampaign = cookiesParams.gtmCampaign ?? urlParams.utm_campaign ?? null
  const gtmContent = cookiesParams.gtmContent ?? urlParams.utm_content ?? null
  const gtmMedium = cookiesParams.gtmMedium ?? urlParams.utm_medium ?? null

  return { gtmSource, gtmMedium, gtmCampaign, gtmContent }
}

export const FreeRegistrationScreen = ({ history }) => {
  useBodyClass('app')

  const search = location.search
    .replace('appurl', 'appUrl')
    .replace('appDestinationUrl', 'appUrl')
    .replace('appuestinationurl', 'appUrl')

  const urlParams = useRef<UrlParams>(QueryString.parse(search) as UrlParams)
  const utmParams = extractUtmParams()
  const queryString = useRef(search)
  const [paramsEmail, _setParamsEmail] = useState(extractEmail(urlParams.current))
  const [error, setErrorMessage] = useState('')
  const [loginEmail, setLoginEmail] = useState('')
  const [loadingStep, _setLoadingStep] = useState(false)
  const [alertMessage, showAlertMessage] = useState(false)
  const [gaClientId, setGaClientId] = useState(extractGaId(urlParams.current))
  const [ipAddress, setIpAddress] = useState<string>('')
  const { spacing, borders, colors } = useTheme()

  const { sendToGTM } = useGTMCheckout()

  const authToken = extractAuthToken()

  const handleForm = async (values, setSubmitting) => {
    const { email, password } = values
    const utmData = prepareUtmData(urlParams.current, utmParams)
    try {
      delete urlParams.current.email
      const result = await createFreeAccount({
        email,
        password,
        gaClientId,
        ipAddress,
        originDeviceOs: osName,
        ...urlParams.current,
        ...utmData,
      })
      if (result.status === 409) {
        setSubmitting(false)
        setLoginEmail(email)
        showAlertMessage(true)
        return
      }
      if (result.status === 401) {
        setSubmitting(false)
        setErrorMessage(
          "L'email che hai inserito non è valida, prova ad utilizzare un altro indirizzo email",
        )
        return
      }

      const redirectUrl = createForwardLink(urlParams.current, queryString.current)
      //@ts-ignore
      $FPROM.trackSignup(
        {
          email: email,
        },
        () => {},
      )
      sendToGTM({
        event: 'accountCreated',
        checkoutStep: 1,
        method: 'email',
        user_data: {
          email,
        },
      })
      setTimeout(() => {
        window.location.replace(redirectUrl)
      }, 1500)
    } catch (error) {
      setErrorMessage(
        "Si è verificato un errore durante la creazione dell'account, riprova o contattaci in live chat",
      )
      setSubmitting(false)
    }
  }

  useEffect(() => {
    const hiddenParameters = ['next_step', 'email', 'appUrl', 'plan', 'coupon', 'social']
    const creaAccountUrl =
      search.length > 0
        ? QueryString.exclude(`/crea-account/?${search}`, hiddenParameters)
        : '/crea-account'
    window.history.pushState(null, '', creaAccountUrl)

    if (authToken) {
      if (urlParams.current.appUrl) {
        window.location.replace(urlParams.current.appUrl)
      } else if (urlParams.current.next_step) {
        window.location.replace(urlParams.current.next_step)
      } else {
        history.push('/')
      }
    }

    // workaround next_step cookie
    const cookieNextStep = auth.getNextStep()
    if (cookieNextStep === undefined || cookieNextStep === '' || cookieNextStep === null) {
      auth.setNextStep(urlParams.current.next_step ?? env.SECOND_STEP_URL)
    }

    sendToGTM({
      event: 'checkoutStep',
      productName: 'Abbonamento Free',
      productSKU: 'free-subscription',
      price: 0,
      subscriptionPeriod: 'Monthly',
      trial: false,
      discount: false,
      checkoutStep: 1,
      next_step: urlParams.current?.next_step ?? env.SECOND_STEP_URL,
    })

    if (!gaClientId) {
      window.ga(tracker => {
        const id = tracker.get('clientId')
        setGaClientId(id)
      })
    }

    const getCountryCode = async () => {
      try {
        const { ipAddress } = await getCountryCodeFromIp()
        setIpAddress(ipAddress)
      } catch (error) {
        console.log(error)
      }
    }

    getCountryCode()
  }, [])

  if (loadingStep) {
    return (
      <div className='fullScreenLoader'>
        <Loader type='TailSpin' color='black' height={60} width={60} />
      </div>
    )
  }

  return (
    <div className={style.root}>
      <div className={style.backgroundImage} id='login-background-container'></div>
      <LiveChat />
      <VerticalStack pt={spacing.space_12} pb={{ _: spacing.space_20, lg: '300px' }}>
        {/* <Header type='dark' /> */}
        <HorizontalStack
          flex={1}
          mt={{ _: spacing.space_10, small: 0 }}
          alignItems={{ _: 'start', small: 'center' }}
          justifyContent='center'>
          <VerticalStack
            maxWidth='500px'
            borderRadius={borders.radius.large}
            backgroundColor={colors.modal_background}
            paddingY={{ _: spacing.space_8, small: spacing.space_10 }}
            paddingX={{ _: spacing.space_4, small: spacing.space_10 }}
            mx={spacing.space_4}
            borderWidth={spacing.space_035}
            borderStyle='solid'
            borderColor={colors.edge}
            boxShadow={`0 0 ${spacing.space_16} ${spacing.space_16} #000000a1`}>
            <VerticalStack alignItems='center'>
              <Title variant='heading3xl' mb={spacing.space_4} alignment='center'>
                Crea il tuo account
              </Title>
              <Text fontWeight='light' alignment='center' mb={spacing.space_8}>
                Crea il tuo account per accedere subito a Learnn. Puoi utilizzare un indirizzo email
                o accedere tramite social.
              </Text>
            </VerticalStack>
            <VerticalStack>
              <VerticalStack>
                {alertMessage ? (
                  <AlertBanner
                    onClick={() =>
                      history.push({
                        pathname: '/login/',
                        search:
                          urlParams.current.appUrl || urlParams.current.next_step
                            ? `?appurl=${urlParams.current.appUrl ?? urlParams.current.next_step}`
                            : '',
                        state: {
                          email: loginEmail,
                          message:
                            'Hai già un account, effettua il login per gestire lo stato del tuo abbonamento.',
                        },
                      })
                    }
                  />
                ) : null}
                <Formik
                  initialValues={{ email: paramsEmail, password: '' }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    handleForm(values, setSubmitting)
                  }}>
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <TextInput
                        type='email'
                        name='email'
                        placeholder='Inserisci la tua email'
                        size='xl'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        borderColor={colors.outline}
                        borderWidth={spacing.space_035}
                      />
                      <Text fontWeight='light' color='error' variant='bodyXs' mt={spacing.space_1}>
                        {errors.email && touched.email && errors.email}
                      </Text>

                      <HorizontalStack mt={spacing.space_5} width='100%'>
                        <InputPassword
                          placeholder='Inserisci la password'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          name='password'
                        />
                      </HorizontalStack>
                      <Text fontWeight='light' color='error' variant='bodyXs' mt={spacing.space_1}>
                        {errors.password && touched.password && errors.password}
                      </Text>

                      {error ? (
                        <Text
                          fontWeight='light'
                          color='error'
                          variant='bodyXs'
                          mt={spacing.space_1}>
                          {error}
                        </Text>
                      ) : null}

                      <HorizontalStack justifyContent='center'>
                        <Button
                          width='100%'
                          mt={spacing.space_4}
                          variant='primary'
                          label='CONTINUA'
                          loading={isSubmitting}
                          size='lg'
                        />
                      </HorizontalStack>
                    </form>
                  )}
                </Formik>
              </VerticalStack>
              <HorizontalStack alignItems={'center'} my={spacing.space_5}>
                <Box backgroundColor={colors.outline} height={'1px'} flex={1} />
                <Text mx={spacing.space_4} color={'secondary'}>
                  oppure
                </Text>
                <Box backgroundColor={colors.outline} height={'1px'} flex={1} />
              </HorizontalStack>
              <SocialLoginButtons
                next_step={urlParams?.current?.next_step}
                gtmSource={utmParams.gtmSource}
                gtmCampaign={utmParams.gtmCampaign}
                gtmContent={utmParams.gtmContent}
                gtmMedium={utmParams.gtmMedium}
                type='register'
              />
            </VerticalStack>
            <HorizontalStack alignContent='center' justifyContent='centerg'>
              <Text
                fontWeight='light'
                textAlign='center'
                variant='bodyXs'
                mb={spacing.space_1}
                mt={spacing.space_8}>
                Continuando accetti la
                <a
                  href='https://www.iubenda.com/privacy-policy/54290035'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <span className={style.privacyTextBold}>privacy policy </span>
                </a>
                ed i
                <a
                  href='https://learnn.com/termini-e-condizioni/'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <span className={style.privacyTextBold}>termini del servizio</span>
                </a>
              </Text>
            </HorizontalStack>
            <Row className='justify-content-center mt-5'>
              <VerticalStack alignItems='center'>
                <Text fontWeight='light' mb={spacing.space_2} mt={spacing.space_4}>
                  Hai già un account?
                </Text>
                <button className={style.loginButton} onClick={() => goToLogin(urlParams.current)}>
                  <Text variant='bodySm' fontWeight='black'>
                    Accedi al tuo account Learnn <FontAwesomeIcon size='xs' icon={faChevronRight} />
                  </Text>
                </button>
              </VerticalStack>
            </Row>
          </VerticalStack>
        </HorizontalStack>
      </VerticalStack>
      <Footer type='dark-minified' />
    </div>
  )
}
