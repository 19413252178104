import { useEffect, useState, useRef, forwardRef } from 'react'
import { Formik, FormikProps } from 'formik'
import { validationSchema, billingValidationSchema } from './validation'
import { AddressAutocomplete } from '../AddressAutocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faApple, faGooglePay } from '@fortawesome/free-brands-svg-icons'

import {
  getPaymentIntent,
  paySubscription,
  giftSubscription,
  getCountryCodeFromIp,
  checkPaymentStatus,
  ValidatedPlan,
  GiftDetails,
  Addon,
} from '../../controllers/Payment'
import {
  extractPlanPeriod,
  getErrorMessage,
  setAddress,
  calculateTotalPrice,
} from '../../utils/checkout'
import { chargebeeInstance } from '../../utils/chargebee'
import usePolling from '../../utils/usePolling'
import { useGTMCheckout } from '../../analytics/gtm'
import env from '../../env.json'

import style from './StripePayment.module.css'
import cx from 'classnames'
import { useCheckoutErrorHandler } from '../../screens/PaymentScreen/errorHandling'
import { FormActions } from '../../screens/PaymentScreen/PaymentScreen'
import { osName } from 'react-device-detect'

type IStripePaymentProps =
  | {
      type: 'standard'
      userToken: string
      plan?: string
      planInfo: ValidatedPlan | undefined
      coupon: string
      showCoupon: boolean
      setCoupon(id: string): void
      setCouponPrice(price: number): void
      price?: number
      isTrial: boolean
      trialPeriod?: number
      onSuccess(): void
      onCouponSubmit(): Promise<ValidatedPlan>
      creditNotes?: number
      addon: Addon
      planQuantity?: number
      method: 'apple-pay' | 'google-pay'
      togglePaymentLoading(loading: boolean): void
      setErrorMessage(message: string): void
      isVatExempt: boolean
      onVatExemptChange: (value: boolean) => void
    }
  | {
      type: 'gift'
      userToken: string
      plan: string
      planInfo: ValidatedPlan | undefined
      coupon: string
      showCoupon: boolean
      setCoupon(id: string): void
      setCouponPrice(price: number): void
      price?: number
      giftDetails: GiftDetails
      onSuccess(): void
      onCouponSubmit(): Promise<ValidatedPlan>
      creditNotes?: number
      method: 'apple-pay' | 'google-pay'
      togglePaymentLoading(loading: boolean): void
      setErrorMessage(message: string): void
      isVatExempt: boolean
      onVatExemptChange: (value: boolean) => void
    }

type Polling = {
  running: boolean
  uuid: string | null
  userEmail: string | null
  userName: string | null
  userLastName: string | null
}

type FormValues = {
  name: string
  last_name: string
  billingName: string
  pec: string
  codiceDestinatario: string
  pIva: string
  cfCodiceFiscale: string
  billingAddress: string
  billingCity: string
  provincia: string
  cap: string
  country: string
  fullAddress: string
}

type PayerAccountDetails =
  | {
      email: string
    }
  | undefined

const StripePayment = forwardRef<FormActions, IStripePaymentProps>((props, ref) => {
  const formRef = useRef<FormikProps<FormValues>>()
  const failedAttempts = useRef(0) //@ts-ignore
  const paymentInstance = useRef<any>()
  const [paymentAuthorized, setPaymentAuthorized] = useState(false) //@ts-ignore
  const [paymentDetails, setPaymentDetails] = useState<PayerAccountDetails>()
  const [paymentIntentId, setPaymentIntentId] = useState('')
  const [billingCountry, setBillingCountry] = useState('IT')
  const [ipAddress, setIpAddress] = useState<string>('')
  const [billingEnabled, setBilling] = useState(false)

  const [polling, setPolling] = useState<Polling>({
    running: false,
    uuid: null,
    userEmail: null,
    userName: null,
    userLastName: null,
  })

  const { sendToGTM } = useGTMCheckout()
  const { handleError } = useCheckoutErrorHandler()

  const originDeviceOs = osName

  const getFinalPrice = (price: number | undefined) => {
    const finalPrice =
      props.type === 'gift'
        ? calculateTotalPrice(price, 0, 0, props.creditNotes)
        : calculateTotalPrice(price, props.addon?.price, props.addon?.quantity, props.creditNotes)
    const amount = Math.round(100 * finalPrice)

    return amount
  }

  const handleForm = async ({
    values,
    setSubmitting,
  }: {
    values: FormValues
    setSubmitting(isSubmitting: boolean): void
  }) => {
    props.togglePaymentLoading(true)
    try {
      if (props.type === 'gift') {
        const { giftDetails, plan, userToken, coupon, price, onSuccess } = props
        const giftResult = await giftSubscription({
          planId: plan,
          userToken,
          vatExempt: props.isVatExempt,
          paymentMethod: props.method,
          paymentId: paymentIntentId,
          gifter: {
            email: giftDetails.senderEmail,
            signature: giftDetails.senderName,
            note: giftDetails.senderMessage ?? '',
            first_name: values.name,
            last_name: values.last_name,
            vat_number: values.pIva,
            cf_codice_fiscale: values.cfCodiceFiscale,
            cf_codice_destinatario: values.codiceDestinatario,
          },
          gifted: {
            first_name: giftDetails.receiverName,
            last_name: giftDetails.receiverLastName,
            email: giftDetails.receiverEmail,
          },
          billingData: {
            company: values.billingName,
            email: values.pec,
            line1: values.billingAddress,
            city: values.billingCity,
            zip: values.cap,
            country: values.country !== '' ? values.country : billingCountry,
            state: values.provincia,
          },
          scheduledAt: giftDetails.scheduledAt ? giftDetails.scheduledAt : undefined,
          couponCodes: coupon ? [coupon] : [],
          ipAddress,
          originDeviceOs,
        })
        await sendToGTM({
          event: 'checkoutCompleted',
          productName:
            plan === 'regalo---1-anno'
              ? 'Regalo Annuale'
              : plan === 'regalo---6-mesi'
              ? 'Regalo 6 mesi'
              : 'Regalo 3 mesi',
          productSKU: plan,
          trial: false,
          price: price,
          paymentMethod: 'card',
          subscriptionPeriod:
            plan === 'regalo---1-anno'
              ? 'Yearly'
              : plan === 'regalo---6-mesi'
              ? '6 Months'
              : '3 Months',
          discount: coupon ? true : false,
          transactionId: giftResult.uuid,
          couponName: coupon ?? '',
          productVariant: 'gift',
          user_data: {
            email: giftDetails.senderEmail,
            first_name: values.name,
            last_name: values.last_name,
          },
        })
        props.setErrorMessage('')
        onSuccess()
      } else {
        const { addon, plan, userToken, coupon, planQuantity } = props
        const result = await paySubscription({
          planId: plan ?? 'free',
          userToken,
          vatExempt: props.isVatExempt,
          paymentMethod: props.method,
          paymentId: paymentIntentId,
          customerData: {
            first_name: values.name,
            last_name: values.last_name,
            vat_number: values.pIva,
            cf_codice_fiscale: values.cfCodiceFiscale,
            cf_codice_destinatario: values.codiceDestinatario,
          },
          couponCodes: coupon ? [coupon] : [],
          addons: addon ? [{ id: addon.id, quantity: addon.quantity }] : [],
          ipAddress,
          billingData: {
            company: values.billingName,
            email: values.pec,
            line1: values.billingAddress,
            city: values.billingCity,
            zip: values.cap,
            country: values.country !== '' ? values.country : billingCountry,
            state: values.provincia,
          },
          ...(planQuantity && { planQuantity }),
        })

        setPolling({
          running: true,
          uuid: result.uuid,
          userEmail: result.userEmail,
          userName: result.userName,
          userLastName: result.userLastName,
        })
        setTimeout(() => {
          setPolling({
            running: false,
            uuid: null,
            userEmail: null,
            userName: null,
            userLastName: null,
          })
          setSubmitting(false)
          props.togglePaymentLoading(false)
          props.setErrorMessage(getErrorMessage('genericError'))
        }, env.MAX_PAYMENT_POLLING ?? 15000)
      }
    } catch (error: any) {
      setSubmitting(false)
      props.togglePaymentLoading(false)
      handleError(error)
      if (error.message === 'GIFTED_INVALID_SUBSCRIPTION')
        return props.setErrorMessage(getErrorMessage('giftedInvalidSubscriptionMessage'))
      props.setErrorMessage(getErrorMessage('genericError'))
    }
  }

  useEffect(() => {
    const finalPrice = getFinalPrice(props.price)
    chargebeeInstance.load(props.method).then((paymentHandler: any) => {
      paymentInstance.current = paymentHandler
      getPaymentIntent(finalPrice, props.method)
        .then(payment_intent => {
          const buttonId = `#${props.method}-button`
          paymentHandler.setPaymentIntent(payment_intent)

          const buttonHolder = document.querySelector(buttonId)
          if (buttonHolder && buttonHolder.innerHTML.trim().length > 0) {
            buttonHolder.innerHTML = ''
          }

          if (props.method === 'google-pay') {
            return paymentHandler.mountPaymentButton(
              buttonId,
              {
                buttonType: 'long',
                buttonSizeMode: 'fill',
              },
              { requestPayerEmail: true },
            )
          } else {
            return paymentHandler.mountPaymentButton(buttonId, {
              locale: 'it_IT',
              buttonColor: 'black',
              buttonType: 'subscribe',
            })
          }
        })
        .then(() => {
          // once button mounted
          return paymentHandler.handlePayment()
        })
        .then(result => {
          console.log(result)
          const { paymentIntent } = result
          const email = paymentIntent.payer_info.customer.email
          setPaymentIntentId(paymentIntent.id)
          if (email)
            setPaymentDetails({
              email,
            })
          setPaymentAuthorized(true)
        })
        .catch(error => {
          console.log(error)
        })
    })

    const getCountryCode = async () => {
      try {
        const { countryCode, ipAddress } = await getCountryCodeFromIp()
        setBillingCountry(countryCode)
        setIpAddress(ipAddress)
      } catch (error) {
        console.log(error)
      }
    }

    getCountryCode()
  }, [props.price])

  usePolling(
    async () => {
      if (!polling.uuid) return
      const result = await checkPaymentStatus(polling.uuid, props.userToken)

      if (result.status === 'completed') {
        if (props.type === 'standard') {
          await sendToGTM({
            event: 'checkoutCompleted',
            productName: props.planInfo
              ? props.planInfo.invoice_name
              : props.addon
              ? props.addon.name
              : '',
            productSKU: props.plan ?? props.addon?.id ?? '',
            trial: props.isTrial ? true : false,
            price: calculateTotalPrice(props.price, props.addon?.price, props.addon?.quantity),
            paymentMethod: 'paypal',
            subscriptionPeriod: props.planInfo
              ? extractPlanPeriod(props.planInfo.period, props.planInfo.period_unit)
              : 'one-time',
            discount: props.coupon ? true : false,
            transactionId: result.subscriptionId ?? '',
            couponName: props.coupon ?? '',
            user_data: {
              email: polling.userEmail,
              first_name: polling.userName,
              last_name: polling.userLastName,
            },
            ...(props.addon && {
              addon_id: props.addon.id,
              addon_qty: props.addon.quantity,
              addon_value: props.addon.price,
            }),
          })
        }

        setPolling({
          running: false,
          uuid: null,
          userEmail: null,
          userName: null,
          userLastName: null,
        })
        setTimeout(() => {
          props.setErrorMessage('')
          formRef.current?.setSubmitting(false)
          props.togglePaymentLoading(false)
          props.onSuccess()
        }, 2000)
      } else if (result.status === 'failed') {
        failedAttempts.current = failedAttempts.current + 1

        if (failedAttempts.current >= 2) {
          setPolling({
            running: false,
            uuid: null,
            userEmail: null,
            userName: null,
            userLastName: null,
          })
          const errorMessage = getErrorMessage(result.reason)
          formRef.current?.setSubmitting(false)
          handleError(result.reason)
          props.togglePaymentLoading(false)
          props.setErrorMessage(errorMessage)
        }
      }
    },
    polling.running ? env.PAYMENT_POLLING_INTERVAL ?? 3000 : null,
  )

  return (
    <>
      {!paymentAuthorized ? (
        <div>
          <p>
            {`Per concludere la registrazione accedi a `}
            <span style={{ textTransform: 'capitalize' }}>{props.method.split('-')[0]}</span>
            {` Pay e autorizza il pagamento.`}
          </p>
        </div>
      ) : (
        <></>
      )}
      {paymentAuthorized ? (
        <>
          {paymentDetails ? (
            <div className={style.emailInfoContainer}>
              <div className={style.emailInfoText}>
                <FontAwesomeIcon
                  icon={props.method === 'google-pay' ? faGooglePay : faApple}
                  size='lg'
                  className='mr-1'
                />
              </div>
              <div className={style.emailInfoEmail}>{paymentDetails.email}</div>
            </div>
          ) : (
            <div className={style.emailInfoContainer}>
              <div className={style.emailInfoText}>
                <FontAwesomeIcon
                  icon={props.method === 'google-pay' ? faGooglePay : faApple}
                  size='lg'
                  className='mr-1'
                />
                {`${props.method.split('-')[0]} Pay`}
              </div>
              <div className={style.emailInfoEmail}>Pagamento Autorizzato</div>
            </div>
          )}
          <Formik
            innerRef={e => {
              if (e) {
                formRef.current = e

                const controls: FormActions = {
                  submit: () => {
                    formRef.current?.submitForm()
                  },
                }
                if (typeof ref === 'function') {
                  ref(controls)
                }
              }
            }}
            initialValues={{
              name: '',
              last_name: '',
              billingName: '',
              pec: '',
              pIva: '',
              cfCodiceFiscale: '',
              codiceDestinatario: '',
              billingAddress: '',
              billingCity: '',
              provincia: '',
              cap: '',
              country: '',
              fullAddress: '',
            }}
            validationSchema={billingEnabled ? billingValidationSchema : validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleForm({ values, setSubmitting })
            }}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldError,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className={cx([style.adaptiveFields, 'mt-5'])}>
                  <div className={style.adaptiveField}>
                    <input
                      name='name'
                      className={values.name ? cx([style.input, style.val]) : style.input}
                      type='text'
                      placeholder='Nome'
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label className={style.label}>Nome</label>
                    <i className={style.bar}></i>
                    <div className={style.errorText}>
                      {errors.name && touched.name && errors.name}
                    </div>
                  </div>
                  <div className={cx([style.adaptiveField, style.rightField])}>
                    <input
                      name='last_name'
                      className={values.last_name ? cx([style.input, style.val]) : style.input}
                      type='text'
                      placeholder='Cognome'
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label className={style.label}>Cognome</label>
                    <i className={style.bar}></i>
                    <div className={style.errorText}>
                      {errors.last_name && touched.last_name && errors.last_name}
                    </div>
                  </div>
                </div>
                {billingEnabled ? (
                  <>
                    <div className={cx([style.field, 'mt-5'])}>
                      <input
                        name='billingName'
                        className={values.billingName ? cx([style.input, style.val]) : style.input}
                        type='text'
                        placeholder='Ragione sociale'
                        value={values.billingName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label className={style.label}>Ragione sociale</label>
                      <i className={style.bar}></i>
                      <div className={style.errorText}>
                        {errors.billingName && touched.billingName && errors.billingName}
                      </div>
                    </div>
                    <div className={style.adaptiveFields}>
                      <div className={style.adaptiveField}>
                        <input
                          name='pec'
                          className={values.pec ? cx([style.input, style.val]) : style.input}
                          type='text'
                          placeholder='Ragione sociale'
                          value={values.pec}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label className={style.label}>PEC</label>
                        <i className={style.bar}></i>
                        <div className={style.errorText}>
                          {errors.pec && touched.pec && errors.pec}
                        </div>
                      </div>
                      <div className={cx([style.adaptiveField, style.rightField])}>
                        <input
                          name='codiceDestinatario'
                          className={
                            values.codiceDestinatario ? cx([style.input, style.val]) : style.input
                          }
                          type='text'
                          placeholder='Codice Destinatario'
                          value={values.codiceDestinatario}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label className={style.label}>Codice Destinatario</label>
                        <i className={style.bar}></i>
                        <div className={style.errorText}>
                          {errors.codiceDestinatario &&
                            touched.codiceDestinatario &&
                            errors.codiceDestinatario}
                        </div>
                      </div>
                    </div>

                    <div className={style.adaptiveFields}>
                      <div className={style.adaptiveField}>
                        <input
                          name='pIva'
                          className={values.pIva ? cx([style.input, style.val]) : style.input}
                          type='text'
                          placeholder='Ragione sociale'
                          value={values.pIva}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label className={style.label}>Partita IVA</label>
                        <i className={style.bar}></i>
                        <div className={style.errorText}>
                          {errors.pIva && touched.pIva && errors.pIva}
                        </div>
                      </div>
                      <div className={cx([style.adaptiveField, style.rightField])}>
                        <input
                          name='cfCodiceFiscale'
                          className={
                            values.cfCodiceFiscale ? cx([style.input, style.val]) : style.input
                          }
                          type='text'
                          placeholder='Codice Fiscale'
                          value={values.cfCodiceFiscale}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label className={style.label}>Codice Fiscale</label>
                        <i className={style.bar}></i>
                        <div className={style.errorText}>
                          {errors.cfCodiceFiscale &&
                            touched.cfCodiceFiscale &&
                            errors.cfCodiceFiscale}
                        </div>
                      </div>
                    </div>
                    <AddressAutocomplete
                      name='fullAddress'
                      onChange={data => setAddress(data, setFieldValue, setFieldError)}
                      onBlur={handleBlur}
                      errors={errors.fullAddress && touched.fullAddress && errors.fullAddress}
                    />
                    <label
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 30,
                        justifyContent: 'space-between',
                      }}>
                      <input
                        name='billing'
                        type='checkbox'
                        checked={props.isVatExempt}
                        onChange={() => props.onVatExemptChange(!props.isVatExempt)}
                      />
                      <span className={style.vatExemptText} style={{ flex: 1 }}>
                        Sono una PA (esente IVA)
                      </span>
                    </label>
                  </>
                ) : (
                  <></>
                )}
                <label style={{ display: 'flex', alignItems: 'center', marginBottom: 30 }}>
                  <input
                    name='billing'
                    type='checkbox'
                    checked={billingEnabled}
                    onChange={() => {
                      if (billingEnabled) props.onVatExemptChange(false)
                      setBilling(!billingEnabled)
                    }}
                  />
                  <span className='billing-text ml-2'>Desidero ricevere la fattura</span>
                </label>
              </form>
            )}
          </Formik>
        </>
      ) : (
        <div className='mt-4 mb-5' id={`${props.method}-button`}></div>
      )}
    </>
  )
})

export default StripePayment
