import env from '../env.json'
import { FailureReason } from '../utils/checkout'
import { restClient } from './rest'
import auth from '../utils/auth'
import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
} from '@fortawesome/free-brands-svg-icons'
import { CardBrand, PaymentSourceStatus, PaymentSourceType } from '@learnn/sdk/src/api/billing'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'

type PaymentData = {
  planId: string | undefined
  userToken: string
  ipAddress?: string
  paymentMethod: 'card' | 'paypal_express_checkout' | 'apple-pay' | 'google-pay' | 'direct_debit'
  paymentId: string
  customerData: CustomerData
  couponCodes: [string] | []
  addons: [{ id: string; quantity: number }] | []
  billingData: BillingData
  vatExempt: boolean
}

type GiftData = {
  planId: string
  userToken?: string
  paymentMethod: 'card' | 'paypal_express_checkout' | 'apple-pay' | 'google-pay' | 'direct_debit'
  paymentId?: string
  gifter: Gifter
  gifted: Gifted
  billingData: BillingData
  couponCodes: [string] | []
  scheduledAt: string | undefined
  ipAddress: string
  originDeviceOs: string
  vatExempt: boolean
}

type Gifter = {
  email: string
  signature: string
  note: string
  first_name: string
  last_name: string
  vat_number: string | undefined
  cf_codice_fiscale: string | undefined
  cf_codice_destinatario: string | undefined
}

type Gifted = {
  first_name: string
  last_name: string
  email: string
}

export type GiftDetails = {
  senderEmail: string
  receiverName: string
  receiverLastName: string
  receiverEmail: string
  senderMessage: string
  senderName: string
  scheduledAt?: string
}

export type PaymentIntent = {
  active_payment_attempt: {
    id_at_gateway: string
  }
}

type CustomerData = {
  first_name: string
  last_name: string
  vat_number: string | undefined
  cf_codice_fiscale: string | undefined
  cf_codice_destinatario: string | undefined
}

type BillingData = {
  company: string | undefined
  email: string | undefined
  line1: string | undefined
  city: string | undefined
  zip: string | undefined
  country: string
  state: string | undefined
}

type PaymentResult = { uuid: string; userEmail: string; userName: string; userLastName: string }

type PaymentStatus =
  | {
      status: 'completed'
      subscriptionId: string
      cbCustomerId: string
    }
  | { status: 'inProgress' }
  | { status: 'failed'; reason: FailureReason }

export type Card = {
  brand: CardBrand
  expiryMonth: string
  expiryYear: string
  firstName: string
  lastName: string
  fundingType: string
  last4: string
  maskedNumber: string
}
export type PaypalExpress = {
  email: string
}

export type PaymentSource = {
  id: string
  primary: boolean
  createdAt: string
  customerId: string
  deleted: boolean
  gateway: string
  status: PaymentSourceStatus
  type: PaymentSourceType
  card: Card | undefined
  paypal: PaypalExpress | undefined
}

export type Addon =
  | {
      id: string
      name: string
      description: string
      price: number
      quantity: number
    }
  | undefined

export type DiscountDetails = {
  discountType: string
  discountPercentage: number
  discountAmount: number
}
export type CustomPrice = {
  customMessage?: string
  displayPrice?: number
}
export type ValidatedPlan = {
  base_price: number
  price: number
  invoice_name: string
  trial: TrialPlan
  period: number
  period_unit: string
  customPrice?: CustomPrice
  discount?: DiscountDetails
  couponInvoiceName?: string
}

export type AddonPaymentResult = {
  result: 'success'
  uuid: string
  invoiceId: string
  invoiceAmount: number
}

export type UpgradeDowngradeResult = {
  result: 'success'
  uuid: string
  customerId: string
  planId: string
  subscriptionId: string
  actionState: 'upgrade' | 'switch-paid' | 'reactive-free' | 'reactive-paid' | 'downgrade'
}

export type TrialPeriodUnit = 'day' | 'month'
export type TrialPlan =
  | {
      period: number
      unit: TrialPeriodUnit
      basePrice: number
    }
  | undefined

export const paySubscription = async (paymentData: PaymentData): Promise<PaymentResult> => {
  try {
    const result = await restClient.jsonPost(`${env.CHECKOUT_API_URL}/signup/upgrade`, paymentData)
    if (!result || result.status !== 200) {
      throw new Error('Errore durante il pagamento')
    }

    return result.body
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const checkPaymentStatus = async (
  uuid: string,
  userToken: string,
): Promise<PaymentStatus> => {
  const result = await restClient.jsonPost(`${env.CHECKOUT_API_URL}/signup/upgrade/status`, {
    uuid,
    userToken,
  })
  if (!result || result.status !== 200) {
    throw new Error('Errore durante il pagamento')
  }
  return result.body
}

export const checkSubscriptionStatus = async (apiToken: string) => {
  try {
    const result = await restClient.jsonGet(
      `${env.MY_API_URL}/services/referral/dashboard/${apiToken}`,
    )
    if (!result) {
      throw new Error('Cannot get data')
    }
    if (result.status !== 200) {
      throw new Error(result.body.message)
    }
    console.log('API call:', result)
    return result.body
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const giftSubscription = async (paymentData: GiftData) => {
  try {
    const result = await restClient.jsonPost(`${env.CHECKOUT_API_URL}/signup/gift`, paymentData)
    if (
      result &&
      result.status === 400 &&
      result.body.result === 'error_check_chargebee_gifted_valid_subscription'
    ) {
      throw new Error('GIFTED_INVALID_SUBSCRIPTION')
    }
    if (!result || result.status !== 200) {
      throw new Error('Errore durante il pagamento')
    }

    return result.body
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const setupPayPal = async () => {
  try {
    const result = await restClient.jsonPost(`${env.CHECKOUT_API_URL}/payments/paypal/setup`)
    if (!result || result.status !== 200) {
      throw new Error('Errore durante il pagamento')
    }
    return result.body.token
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getPaymentIntent = async (
  price: number,
  method: 'card' | 'apple-pay' | 'google-pay' | 'direct_debit',
) => {
  try {
    const result = await restClient.jsonPost(`${env.CHECKOUT_API_URL}/payments/stripe/setup`, {
      price,
      method: method.replace('-', '_'),
    })
    if (!result || result.status !== 200) {
      throw new Error('Errore durante il pagamento')
    }
    return result.body.paymentIntent
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const applyCoupon = async ({
  plan,
  coupon,
  planQuantity,
  addon,
  addonQuantity,
}: {
  plan?: string
  coupon: string
  planQuantity?: number
  addon?: string
  addonQuantity?: number
}): Promise<ValidatedPlan> => {
  if (!plan) {
    throw 'Nessun piano inserito'
  }

  try {
    const url = new URL(`${env.CHECKOUT_API_URL}/signup/price/${plan}`)
    const params = new URLSearchParams()
    params.append('coupon', String(coupon))
    if (planQuantity) {
      params.append('plan_qty', String(planQuantity))
    }
    if (addon) {
      params.append('addon', addon)
      params.append('addonQuantity', String(addonQuantity || 1))
    }
    url.search = params.toString()

    const result = await restClient.jsonGet(url.toString())
    if (!result || result.status !== 200) {
      throw new Error(result?.body.message)
    }
    return result.body
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const validatePlan = async ({
  plan,
  planQuantity,
  addon,
  addonQuantity,
}: {
  plan: string
  planQuantity?: number
  addon?: string
  addonQuantity?: number
}): Promise<ValidatedPlan> => {
  try {
    const url = new URL(`${env.CHECKOUT_API_URL}/signup/price/${plan}`)
    const params = new URLSearchParams()
    if (planQuantity) {
      params.append('plan_qty', String(planQuantity))
    }
    if (addon) {
      params.append('addon', addon)
      params.append('addonQuantity', String(addonQuantity || 1))
    }
    url.search = params.toString()

    const result = await restClient.jsonGet(url.toString())
    if (!result || result.status !== 200) {
      throw new Error(result?.body.message)
    }
    return result.body
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getPlanDetails = async (planId: string) => {
  const res = await validatePlan({
    plan: planId,
  })
  return res
}

export const getAddonInfo = async (
  addonId: string,
  quantity: number | undefined,
): Promise<Addon> => {
  try {
    const result = await restClient.jsonGet(`${env.CHECKOUT_API_URL}/signup/addon/${addonId}`)
    if (!result || result.status !== 200) {
      throw new Error(result?.body.message)
    }

    const { id, name, description, price } = result.body

    return {
      id,
      name,
      description,
      price,
      quantity: quantity ?? 1,
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getCountryCodeFromIp = async (): Promise<{
  countryCode: string
  ipAddress: string
}> => {
  try {
    const result = await restClient.jsonGet(
      'https://pro.ip-api.com/json/?fields=countryCode,country,status,query&key=Dxtu3sBvwApzk0r',
    )
    if (!result || result.status !== 200 || result.body.status !== 'success') {
      throw new Error(result?.body.message)
    }

    return { countryCode: result.body.countryCode, ipAddress: result.body.query }
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getNextStepFromToken = async (token: string) => {
  try {
    const result = await restClient.jsonGet(`${env.CHECKOUT_API_URL}/signup/token/decode/${token}`)
    if (!result || result.status !== 200) {
      throw new Error('Cannot validate token')
    }
    return result.body.next_step ?? undefined
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createReward = async (email: string) => {
  try {
    const result = await restClient.jsonPost(`${env.CHECKOUT_API_URL}/signup/reward/create`, {
      email,
    })
    if (!result || result.status !== 200) {
      throw new Error('Errore durante la creazione del reward')
    }
  } catch (e) {
    console.error(e)
  }
}

export const checkGifterEmail = async (email: string) => {
  try {
    const result = await restClient.jsonPost(`${env.CHECKOUT_API_URL}/signup/check-gifter`, {
      email,
    })
    if (!result || result.status !== 200) {
      throw new Error('Errore durante la verifica')
    }

    return result.body.alreadyPresent
  } catch (e) {
    console.error(e)
  }
}

export const getCustomerPaymentInfo = async () => {
  try {
    const apiToken = auth.getToken()
    const result = await restClient.jsonPost(`${env.MY_API_URL}/identity/check-payment-method`, {
      apiToken,
    })
    if (!result || result.status !== 200) {
      throw new Error('Errore durante la verifica')
    }
    let response = result.body
    if (result.body.validPaymentSources) {
      const validPaymentMethods = result.body.validPaymentSources
      if (validPaymentMethods.length > 0) {
        response = {
          ...response,
          primaryPaymentMethod: validPaymentMethods.filter(
            (paymentMethod: PaymentSource) => paymentMethod.primary,
          )[0],
        }
      }
    }
    return response
  } catch (e) {
    console.error(e)
    return 0
  }
}

export type CalculateEstimateResponse = {
  future: boolean
  invoice: {
    recurring: boolean
    date?: number
    price_type: string
    sub_total: number
    total: number
    credits_applied?: number
    amount_paid: number
    amount_due: number
    object: string
    customer_id: string
    line_items: Array<{
      id: string
      date_from: number
      date_to: number
      unit_amount: number
      quantity: number
      amount: number
      pricing_model: string
      is_taxed: boolean
      tax_amount: number
      tax_rate: number
      object: string
      customer_id: string
      description: string
      entity_type: 'plan' | 'addon'
      entity_id: string
      discount_amount: number
      item_level_discount_amount: number
    }>
    taxes?: Array<{
      object: string
      name: string
      description: string
      amount: number
    }>
    line_item_taxes?: Array<{
      tax_name: string
      tax_rate: number
      tax_juris_type: string
      tax_juris_name: string
      tax_juris_code: string
      object: string
      line_item_id: string
      tax_amount: number
      is_partial_tax_applied: boolean
      taxable_amount: number
      is_non_compliance_tax: boolean
    }>
    line_item_discounts?: Array<{
      object: string
      line_item_id: string
      discount_type:
        | 'item_level_coupon'
        | 'document_level_coupon'
        | 'promotional_credits'
        | 'prorated_credits'
      discount_amount: number
      coupon_id: string
      entity_id: string
    }>
    currency_code: string
    round_off_amount: number
    discounts?: Array<{
      amount: number
      discount_percentage?: number
      description: string
      entity_type:
        | 'item_level_coupon'
        | 'document_level_coupon'
        | 'promotional_credits'
        | 'prorated_credits'
      entity_id: string
    }>
  }
  credit_notes?: {
    reference_invoice_id: string
    type: string
    price_type: string
    sub_total: number
    total: number
    amount_allocated: number
    amount_available: number
    object: string
    customer_id: string
    line_items: Array<{
      id: string
      date_from: number
      date_to: number
      unit_amount: number
      quantity: number
      amount: number
      pricing_model: string
      is_taxed: boolean
      tax_amount: number
      tax_rate: number
      object: string
      subscription_id: string
      customer_id: string
      description: string
      entity_type: 'plan' | 'addon'
      entity_id: string
      reference_line_item_id: string
      discount_amount: number
      item_level_discount_amount: number
    }>
    taxes: Array<{
      object: string
      name: string
      description: string
      amount: number
    }>
    line_item_taxes: Array<{
      tax_name: string
      tax_rate: number
      tax_juris_type: string
      tax_juris_name: string
      tax_juris_code: string
      object: string
      line_item_id: string
      tax_amount: number
      is_partial_tax_applied: boolean
      taxable_amount: number
      is_non_compliance_tax: boolean
    }>
    currency_code: string
    round_off_amount: number
    line_item_discounts: Array<any>
  }[]
}

export const calculateEstimate = async ({
  plan,
  planQuantity,
  coupon,
  addon,
  addonQuantity,
}: {
  plan?: string
  coupon?: string
  planQuantity?: number
  addon?: string
  addonQuantity?: number
}): Promise<CalculateEstimateResponse> => {
  try {
    const result = await restClient.jsonPost(
      `${env.CHECKOUT_API_URL}/estimate`,
      {
        coupon,
        planId: plan,
        planQuantity: planQuantity ?? (plan ? 1 : undefined),
        addon,
        addonQuantity: addonQuantity ?? (addon ? 1 : undefined),
      },
      { Authorization: `Bearer ${auth.getToken()}` },
    )

    if (!result || result.status !== 200) {
      throw new Error('Estimate process error')
    }
    if (result.body.changeOption === 'immediately') {
      return {
        invoice: result.body.invoice_estimate,
        credit_notes: result.body.credit_note_estimates,
        future: false,
      }
    } else {
      let invoice = result.body.next_invoice_estimate
      if (Object.keys(invoice).length === 0) invoice = result.body.invoice_estimate
      return { invoice: invoice, credit_notes: result.body.credit_note_estimates, future: true }
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}

export type Error<T> = { code: T; message: string }
export const makeError = <T>(code: T, message: string) => ({ code, message })
export type PaymentDeclinedError = Error<'PaymentDeclinedError'>
export type PaymentMethodVerificationError = Error<'PaymentMethodVerificationError'>
export type UnknownError = Error<'UnknownError'>

export type UpgradeDowngradeError =
  | PaymentDeclinedError
  | PaymentMethodVerificationError
  | UnknownError
export const upgradeDowngrade = async ({
  plan,
  planQuantity,
  coupon,
  addon,
}: {
  plan?: string
  coupon?: string
  planQuantity?: number
  addon?: string
}): Promise<E.Either<UpgradeDowngradeError, UpgradeDowngradeResult>> => {
  try {
    const result = await restClient.jsonPost(
      `${env.CHECKOUT_API_URL}/signup/upgrade-downgrade`,
      { coupon, planId: plan, planQuantity, addons: addon ? [addon] : undefined },
      { Authorization: `Bearer ${auth.getToken()}` },
    )

    if (!result)
      return E.left(
        makeError(
          'UnknownError',
          "Si è verificato un errore imprevisto. Contatta l'assistenza per ulteriori dettali",
        ),
      )

    if (result.status === 200) return E.right(result.body)

    if (!(result.body && result.body.data && result.body.data.api_error_code))
      return E.left(
        makeError(
          'UnknownError',
          "Si è verificato un errore imprevisto. Contatta l'assistenza per ulteriori dettali",
        ),
      )

    switch (result.body.data.api_error_code) {
      case 'payment_method_verification_failed':
      case 'payment_processing_failed':
        return E.left(
          makeError(
            'PaymentDeclinedError',
            "Il pagamento non è stato autorizzato. Si prega di utilizzare un metodo di pagamento alternativo o contattare l'assistenza clienti per ulteriore supporto.",
          ),
        )
      default:
        return E.left(
          makeError(
            'UnknownError',
            "Si è verificato un errore imprevisto. Contatta l'assistenza per ulteriori dettali",
          ),
        )
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const addonPurchase = async ({
  addonId,
  addonQuantity,
  coupon,
}: {
  addonId: string
  coupon?: string
  addonQuantity?: number
}): Promise<AddonPaymentResult> => {
  try {
    const result = await restClient.jsonPost(
      `${env.CHECKOUT_API_URL}/signup/addon-purchase`,
      { addonId, addonQuantity, coupon },
      { Authorization: `Bearer ${auth.getToken()}` },
    )

    if (!result || result.status !== 200) {
      throw new Error('Upgrade downgrade process error')
    }

    return result.body
  } catch (e) {
    console.error(e)
    throw e
  }
}

type BasePaymentMethod = {
  id: string
  primary: boolean
  createdAt: string
  customerId: string
  deleted: boolean
  gateway: string
  status: string
}

type CardPayment = BasePaymentMethod & {
  type: 'card'
  card: {
    brand: CardBrand
    expiryMonth: number
    expiryYear: number
    firstName: string
    lastName: string
    fundingType?: string
    last4: string
    maskedNumber: string
  }
}

type PayPalPayment = BasePaymentMethod & {
  type: 'paypal_express_checkout'
  paypal: {
    email: string
  }
}

type ApplePay = BasePaymentMethod & {
  type: 'apple_pay'
  card: {
    brand: CardBrand
    expiryMonth: number
    expiryYear: number
    firstName: string
    lastName: string
    fundingType?: string
    last4: string
    maskedNumber: string
  }
}

type GooglePay = BasePaymentMethod & {
  type: 'google_pay'
  card: {
    brand: CardBrand
    expiryMonth: number
    expiryYear: number
    firstName: string
    lastName: string
    fundingType?: string
    last4: string
    maskedNumber: string
  }
}

type DirectDebitPayment = BasePaymentMethod & {
  type: 'direct_debit'
  bankAccount: {
    nameOnAccount: string
    firstName: string
    lastName: string
    last4: string
    bankName: string
    email: string
  }
}

export type PaymentMethodType = BasePaymentMethod &
  (CardPayment | PayPalPayment | GooglePay | ApplePay | DirectDebitPayment)

export const getPrimaryPaymentMethod = async (): Promise<O.Option<PaymentMethodType>> => {
  const paymentInfo = await getCustomerPaymentInfo()
  if (!paymentInfo.primaryPaymentMethod) return O.none
  return O.some(paymentInfo.primaryPaymentMethod)
}

export const saveBillingData = async (
  billingData: BillingData & {
    cf_codice_fiscale: string | undefined
    cf_codice_destinatario: string | undefined
    vat_number: string | undefined
  },
) => {
  try {
    const result = await restClient.jsonPost(
      `${env.BILLING_API_URL}/invoice/save-billing`,
      billingData,
    )

    if (!result || result.status !== 200) {
      throw new Error('Errore durante il salvataggio')
    }

    console.log('salvato', result)

    return result.body
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const displayCardBrand = (brand: CardBrand) => {
  switch (brand) {
    case 'visa':
      return 'Visa'
    case 'american_express':
      return 'American Express'
    case 'mastercard':
      return 'Mastercard'
    case 'discover':
      return 'Discover'
    case 'diners_club':
      return 'Diners Club'
    case 'jcb':
      return 'JCB'
    case 'other':
    default:
      return 'Carta'
  }
}

export const getCardIcon = (brand: CardBrand) => {
  switch (brand) {
    case 'visa':
      return faCcVisa
    case 'american_express':
      return faCcAmex
    case 'mastercard':
      return faCcMastercard
    case 'discover':
      return faCcDiscover
    case 'diners_club':
      return faCcDinersClub
    case 'jcb':
      return faCcJcb
    case 'other':
    default:
      return faCreditCard
  }
}
